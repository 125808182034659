const StruartStatus = ({ param, isAdmin, onClick }) => {
	const iscancel = param?.stuartStatus === "package_canceled";
	return (
		<div className="d-flex flex-column">
			<p>{param?.stuartStatus || "-"}</p>

			{/* {isAdmin && iscancel ? (
				<div className="">
					<span
						title="Send to telegram"
						className="btn btn-primary shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
						onClick={() => onClick({ data: [param], status: { value: "telegram" } })}>
						<i className="mdi mdi-telegram"></i>
					</span>
					<span
						title="Retry stuart"
						className="btn btn-danger shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
						onClick={() => onClick({ data: [param], status: { label: "retryStuart" } })}>
						<i className="mdi mdi-undo-variant"></i>
					</span>
					<p>{param?.stuartCancelationReason}</p>
				</div>
			) : (
				""
			)} */}
		</div>
	);
};

export default StruartStatus;
