import React, { Component } from "react";

class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
					{/* <h6 className="font-weight-normal mb-3 supportText ">Destek : 0(212) 909 05 44 | 0(535) 833 3625</h6>{" "}
          <h6 className="font-weight-normal mb-3 supportText ">Operasyon Müdürü: 0(533) 920 94 13 | Ferhat Aslan</h6>{" "} */}
					<span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
						Copyright ©{" "}
						<a href="https://papyonsolutions.com/" target="_blank" rel="noopener noreferrer">
							Papyon Solutions{" "}
						</a>
						2025
					</span>
				</div>
			</footer>
		);
	}
}

export default Footer;
