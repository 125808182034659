import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES, STATUSES_OBJECT } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION, ALLERGENS } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CategorySelector, { convertCategoryData } from "containers/Category/CategorySelector";
import TableFiltersSelector from "components/TableFiltersSelector";
import MenuSelector, { convertMenuData } from "containers/Menus/MenuSelector";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import Branch from "containers/Branch";
const List = () => {
	const { t } = useTranslation();
	const [checkList, setCheckList] = useState();
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance

	const { catName, catImage, menuName, ...QueryString } = useQueryString({
		sort: "desc",
	});
	const { page, limit } = QueryString;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const adminState = useSelector((state) => state.admin);
	const isAdmin = adminState.data.userType?.includes("admin");
	const isBranch = adminState.data.userType === "branch";
	const validPermission = adminState?.data?.permissions === true;
	let permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];

	const button = !!(validPermission || permissions?.add) &&
		isAdmin && {
			title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }),
			link: newRoute.base + newRoute.add,
		};
	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, availabilityStatus: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
		{ title: "Details" },
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		isBranch,
		active: {
			manuel: {
				icon: "mdi mdi-content-copy",
				route: "add",
			},
		},
	});

	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");
	const gettingStatistics = async () => {
		return await axiosClient().get(api[API_NAME].list, {
			params: { limit: 0 },
		});
	};

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = {
			ref: `A1:${String.fromCharCode(65 + head.length - 1)}1`,
		};
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, "excel_export.xlsx");
	};

	const handleGetProducts = () => {
		let cols = [
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 30 },
			{ wch: 20 },
		];
		gettingStatistics()
			.then((x) => {
				console.log("x ===>", { x });

				const head = [
					t("productId"),
					t("productName"),
					t("productNameTR"),
					t("productNameEN"),
					t("productNameAR"),
					t("productNameFR"),
					t("branchName"),
					t("price"),
					t("Buying Price"),
					t("description"),
					t("category"),
					t("integrationCode"),
					t("allergies"),
					t("editDate"),
					t("status"),
				];
				const data = x?.data?.result?.map((y) => {
					const lang = y?.translations?.reduce((acc, curr) => {
						acc[curr.language] = curr;
						return acc;
					}, {});

					return {
						[t("productId")]: y?.id,
						[t("productName")]: y?.name,
						[t("productNameTR")]: lang["tr"]?.name,
						[t("productNameEN")]: lang["en"]?.name,
						[t("productNameAR")]: lang["ar"]?.name,
						[t("productNameFR")]: lang["fr"]?.name,
						[t("branchName")]: y?.branch[0].name,
						[t("price")]: y?.price,
						[t("Buying Price")]: y?.buyPrice,
						[t("description")]: y?.shortDesc, // y?.shortDesc,
						[t("category")]: y?.category.name,
						[t("integrationCode")]: y?.integrationCode,
						[t("allergies")]: y?.allergies?.map((x) => t(ALLERGENS[x]?.label))?.join(" , "),
						[t("editDate")]: y?.updatedAt,
						[t("status")]: y?.availabilityStatus || t(STATUSES_OBJECT[y?.isActive]?.label),

						// ceviri_adi: y?.translations.map((z) => String(z.name))?.join("   ,   "),
					};
				});
				console.log({ data });
				exportToCSV({ apiData: data, fileName: "exc", head, cols });
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const changeUrl = (params) => ({
		pathname: location.pathname,
		search: new URLSearchParams(RemoveNullObjectValue({ ...QueryString, ...params })).toString(),
	});

	const selector = [
		<CategorySelector
			value={
				QueryString?.category &&
				convertCategoryData({
					name: catName,
					image: catImage,
					id: QueryString?.category,
				})
			}
			isClearable
			{...{
				label: "category",
				setState: (params) => {
					console.log({ params });
					if (params)
						navigate(
							changeUrl({
								catName: params?.name,
								catImage: params?.image,
								category: params?.value,
							})
						);
					else
						navigate(
							changeUrl({
								catName: null,
								catImage: null,
								category: null,
							})
						);
				},
			}}
		/>,
		<MenuSelector
			isClearable
			value={
				QueryString?.menu &&
				convertMenuData({
					name: menuName,
					id: QueryString?.menu,
				})
			}
			{...{
				label: "menu",
				setState: (params) => {
					console.log({ params });

					if (params)
						navigate(
							changeUrl({
								menuName: params?.name,
								menu: params?.value,
							})
						);
					else
						navigate(
							changeUrl({
								menuName: null,
								menu: null,
							})
						);
				},
			}}
		/>,
	];

	return (
		<div>
			<PageTitle title={t("authorDetails", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} button={button} />

			{isAdmin ? <TableFiltersSelector data={selector} /> : ""}

			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),

						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					enabled: {
						actions: true,
					},
					excelButton: {
						show: isAdmin && true,
						disabled: !data?.result,
						onClick: handleGetProducts,
					},
				}}
			/>
		</div>
	);
};

export default List;
